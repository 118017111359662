/* eslint-disable */
import CheckInService from '../../api/CheckInService.js'

export default{
	name:'ForgetPassword',
	data(){
		return{
			userPhone:'', //手机号
			userCode:'', //验证码
			isGetCode:false,
			codeTime:0,
			sendCode:''
		}
	},
	methods:{
		GetCode(){ //获取验证码
			var that = this
			if(that.userPhone.trim() == ''){
				$('#userPhone').addClass('error')
				$('#userPhone .errorts').text('请输入手机号')
				return
			}else if(!(/^1[3456789]\d{9}$/.test(that.userPhone.trim()))){
				$('#userPhone').addClass('error')
				$('#userPhone .errorts').text('请输入正确格式手机号')
				return
			}
			$('#userPhone').removeClass('error')
			$('#userPhone .errorts').text('')
			CheckInService.SendValidCode('',that.userPhone.trim(),1,0).then(res=>{
				if(res.Tag == 1){
					that.sendCode = res.Description 
				}else{
					that.$alert(res.Message, '提示', {
						confirmButtonText: '确定'
					});
				}
			})
			that.codeTime = 60
			that.isGetCode = true
			var setInterv = setInterval(function() {
				that.codeTime--;
				if (that.codeTime < 0) {
					that.codeTime = 60
					clearInterval(setInterv)
					that.isGetCode = false
				}
			}, 1000)
		},
		SaveEdit(){
			var that = this
			if(that.userPhone.trim() == ''){
				$('#userPhone').addClass('error')
				$('#userPhone .errorts').text('请输入手机号')
				return
			}else if(!(/^1[3456789]\d{9}$/.test(that.userPhone.trim()))){
				$('#userPhone').addClass('error')
				$('#userPhone .errorts').text('请输入正确格式手机号')
				return
			}else if(that.userCode.trim() == ''){
				$('#userCode .login01C').addClass('error')
				$('#userCode .errorts').text('请输入验证码')
				$('#userPhone').removeClass('error')
				$('#userPhone .errorts').text('')
				return
			}else if(that.userCode.trim() != that.sendCode){
				$('#userCode .login01C').addClass('error')
				$('#userCode .errorts').text('请输入正确验证码')
				$('#userPhone').removeClass('error')
				$('#userPhone .errorts').text('')
				return
			}
			$('#userCode .login01C').removeClass('error')
			$('#userCode .errorts').text('')
			$('#userPhone').removeClass('error')
			$('#userPhone .errorts').text('')
			CheckInService.ValidCode('',that.userPhone.trim(),1,0,that.userCode.trim()).then(res=>{
				if(res.Tag == 1){
					this.$router.push({
						path:'/ForgetPasswordSec',
						query:{
							phone:that.userPhone.trim()
						}
					})
				}else{
					that.$alert(res.Message, '提示', {
						confirmButtonText: '确定'
					});
				}
			})
		}
	}
}